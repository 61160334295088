import { Icon, Link } from "antd";
import React from "react";

import PropTypes from "prop-types";

const icons = { fontSize: "24px", marginLeft: "0.25em" };
const Footer = props => {
  const { html, theme } = props;

  return (
    <React.Fragment>
      <footer className="footer">
        <h3>Síguenos en nuestras redes sociales</h3>
        <ul style={{ marginTop: "1em" }}>
          <li>
            <a
              href="https://www.youtube.com/user/hanspoo"
              title="Youtube"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon type="youtube" style={icons} />
            </a>
          </li>
          <li>
            <a
              href="https://github.com/hanspoo"
              title="Github"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon type="github" style={icons} />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/welinux/?hl=es"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon type="instagram" style={icons} />
            </a>
          </li>
        </ul>
        <p>Welinux SpA, Santiago de Chile, info@welinux.cl</p>
      </footer>

      {/* --- STYLES --- */}
      <style jsx>{`
        .anticon {
          font-size: 24px !important;
        }
        .footer {
          a {
            color: white;
          }
          border-top: solid 1px #ccc;
          text-align: center;
          background: lemon;
          padding: ${theme.space.inset.default};
          padding-top: 40px;
          padding-bottom: 80px;

          :global(ul) {
            list-style: none;
            text-align: center;
            padding: 0;

            :global(li) {
              color: ${theme.color.neutral.white};
              font-size: ${theme.font.size.xxs};
              padding: ${theme.space.xxs} ${theme.space.s};
              position: relative;
              display: inline-block;

              &::after {
                content: "•";
                position: absolute;
                right: ${`calc(${theme.space.xs} * -1)`};
              }
              &:last-child::after {
                content: "";
              }
            }
          }
        }

        @from-width desktop {
          .footer {
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Footer.propTypes = {
  html: PropTypes.string,
  theme: PropTypes.object.isRequired
};

export default Footer;
